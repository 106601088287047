import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useAppContext } from '~/modules/context';

import PrivateRoute from '~/components/PrivateRoute';
import PublicRoute from '~/components/PublicRoute';
import Header from '~/containers/Header';
import Bot from '~/routes/Bot';
import Connect from '~/routes/Connect';
import NotFound from '~/routes/NotFound';

function Root() {
  const {
    state: { apiKey },
  } = useAppContext();

  const isAuthenticated = !!apiKey;

  return (
    <BrowserRouter>
      {isAuthenticated && <Header />}
      <Routes>
        <Route
          element={
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Connect />
            </PublicRoute>
          }
          path="/connect"
        />
        <Route
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <Bot />
            </PrivateRoute>
          }
          path="/"
        />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </BrowserRouter>
  );
}

export default Root;
