import { css, Global } from '@emotion/react';

import { theme } from '~/modules/theme';

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        *,
        *:before,
        *:after {
          box-sizing: border-box;
        }

        html {
          font-size: 62.5%;
          -webkit-font-smoothing: antialiased;
          height: 100%;
        }

        body {
          background-color: ${theme.darkColor};
          color: ${theme.white};
          font-family: Rubik, sans-serif;
          font-size: ${theme.typography.regular.fontSize};
          margin: 0;
          min-height: 100vh;
          padding: 0;
        }

        img {
          height: auto;
          max-width: 100%;
        }

        a {
          color: ${theme.white};
          text-decoration: underline;

          &:hover {
            color: ${theme.white};
          }

          &.disabled {
            pointer-events: none;
          }
        }

        label {
          margin-bottom: 0;
        }

        input,
        textarea {
          font-family: inherit;
        }

        [data-component-name='MessageAnswer'] > div > * {
          margin-bottom: ${theme.spacing.sm};
          margin-top: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        ul {
          padding-left: 22px;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
          margin-bottom: ${theme.spacing.lg} !important;
        }

        thead {
          th {
            padding: ${theme.spacing.sm};

            + th {
              border-left: 1px solid ${theme.black};
            }
          }
        }

        tbody {
          tr:nth-of-type(odd) {
            background-color: ${theme.grayDark};
          }

          td {
            padding: ${theme.spacing.sm};
            vertical-align: top;

            + td {
              border-left: 1px solid ${theme.black};
            }
          }
        }

        pre:has(code) {
          background-color: #0d1117;
          border-radius: ${theme.radius.xxs};
          overflow-y: auto;
          padding: ${theme.spacing.sm};

          code {
            display: block;
          }
        }

        code:not([class]) {
          background-color: #0d1117;
          color: ${theme.variants.primary.light.bg};
          display: inline-flex;
          padding: 0 ${theme.spacing.xxs};
        }
      `}
    />
  );
}
