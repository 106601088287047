import { memo, useCallback, useEffect, useState } from 'react';
import { Box, Button, Icon, NonIdealState, Page, Tooltip } from '@gilbarbara/components';
import { createId } from '@paralleldrive/cuid2';
import annyang from 'annyang';
import { ChatCompletionRequestMessage } from 'openai';

import { getCompletion } from '~/modules/ai';
import { useAppContext } from '~/modules/context';
import { parseMessage } from '~/modules/helpers';

import HAL from '~/components/HAL';

import { useHistory, useSpeak } from './hooks';
import Messages from './Messages';

function Bot() {
  const [isFirstEvent, setFirstEvent] = useState(true);
  const { setAppState, state } = useAppContext();
  const { addToHistory, updateHistoryItem } = useHistory();
  const speak = useSpeak();

  const { apiKey, contextSize, history, isListening, isProcessing, isSpeaking, maxTokens } = state;

  const getCompletionWithContext = useCallback(
    async (input: string) => {
      const id = createId();
      const context = history
        .slice(0, contextSize)
        .reverse()
        .reduce<ChatCompletionRequestMessage[]>((acc, d) => {
          acc.push({ role: 'user', content: d.question });

          if (d.answer) {
            acc.push({ role: 'assistant', content: d.answer });
          }

          return acc;
        }, []);

      addToHistory({ id, question: input });

      try {
        const completion = await getCompletion(input, { apiKey, context, maxTokens });

        setAppState({ isProcessing: false });
        updateHistoryItem(id, { answer: completion });

        await speak(parseMessage(completion), { currentId: id }, { currentId: null });
      } catch (error: any) {
        const message = error?.response?.error?.message ?? error.message ?? 'An error occurred';

        updateHistoryItem(id, { error: message });
      }
    },
    [addToHistory, apiKey, history, contextSize, maxTokens, setAppState, speak, updateHistoryItem],
  );

  useEffect(() => {
    annyang.addCallback('result', async (phrases: string[]) => {
      const [phrase] = phrases;

      setAppState({ isProcessing: true });
      annyang.abort();

      await getCompletionWithContext(phrase);
    });

    annyang.addCallback('start', () => {
      setAppState({ isListening: true });
    });

    annyang.addCallback('end', () => {
      setAppState({ isListening: false });
    });

    return () => {
      annyang.removeCallback();
    };
  }, [getCompletionWithContext, setAppState]);

  const handleClickMic = () => {
    if (isListening) {
      annyang.abort();
    } else {
      speechSynthesis.cancel();
      annyang.start();

      if (isFirstEvent) {
        setFirstEvent(false);
        speak('Hello! How can I assist you today?');
      }
    }
  };

  if (!annyang) {
    return <NonIdealState icon="mic" title="Speech recognition not supported" />;
  }

  return (
    <Page centered>
      <HAL active={isProcessing} mb="md" muted={isListening} size={256} speaking={isSpeaking} />
      <Box mb="xl">
        <Tooltip content="Click to speak" open={isFirstEvent} position="top">
          <Button
            invert={!isListening}
            onClick={handleClickMic}
            shape="circle"
            size="lg"
            variant={isListening ? 'green' : 'red'}
          >
            <Icon name="mic" size={32} />
          </Button>
        </Tooltip>
      </Box>
      <Messages />
    </Page>
  );
}

export default memo(Bot);
