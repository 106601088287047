import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@emotion/react';

import { AppProvider } from '~/modules/context';
import { theme } from '~/modules/theme';

import GlobalStyles from '~/components/GlobalStyles';

import Root from './Root';

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <GlobalStyles />
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <Root />
          </AppProvider>
        </ThemeProvider>
      </HelmetProvider>
    </StrictMode>,
  );
}
