import { Link } from 'react-router-dom';
import { Button, NonIdealState, Page } from '@gilbarbara/components';

import Head from '~/containers/Head';

export default function NotFoundRoute() {
  return (
    <Page centered name="NotFound">
      <Head title="404" />
      <NonIdealState type="not-found">
        <Link to="/">
          <Button>Go Home</Button>
        </Link>
      </NonIdealState>
    </Page>
  );
}
