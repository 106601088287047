import { MouseEventHandler } from 'react';
import { Box, BoxInline, ButtonUnstyled, Icon, Loader, Paragraph } from '@gilbarbara/components';
import Markdown from 'markdown-to-jsx';

import { theme } from '~/modules/theme';

import { AppState, History } from '~/types';

interface Props extends History, Pick<AppState, 'currentId' | 'isSpeaking'> {
  onClickRemove: MouseEventHandler;
  onClickSpeak: MouseEventHandler;
}

export default function Message(props: Props): JSX.Element {
  const { answer, currentId, error, id, isSpeaking, onClickRemove, onClickSpeak, question } = props;
  const borderVariant = currentId === id ? 'green' : 'gray';
  const borderShade = currentId === id ? 'mid' : 'dark';

  let content = (
    <div>
      <Loader />
    </div>
  );

  if (error) {
    content = (
      <Paragraph bold variant="red">
        {error}
      </Paragraph>
    );
  } else if (answer) {
    content = <Markdown>{answer}</Markdown>;
  }

  return (
    <Box
      key={id}
      border={[
        {
          side: 'all',
          shade: borderShade,
          variant: borderVariant,
        },
      ]}
      data-component-name="Message"
      mb="sm"
      overflow="hidden"
      radius="xxs"
      shade="darker"
      variant="gray"
    >
      <Box data-component-name="MessagesQuestion" position="relative" px="md" py="sm">
        <Box pr="xl">
          <Paragraph bold shade="light" variant="gray">
            {question}
          </Paragraph>
        </Box>
        <BoxInline ml="auto" position="absolute" right={theme.spacing.xxs} top={theme.spacing.xxs}>
          {answer && (
            <ButtonUnstyled data-id={id} onClick={onClickSpeak} padding="xs">
              <Icon
                name={isSpeaking && currentId === id ? 'play-stop-o' : 'play-button-o'}
                size={20}
              />
            </ButtonUnstyled>
          )}
          <ButtonUnstyled data-id={id} onClick={onClickRemove} padding="xs">
            <Icon name="trash" size={20} />
          </ButtonUnstyled>
        </BoxInline>
      </Box>
      <Box
        border={[{ shade: borderShade, side: 'top', variant: borderVariant }]}
        data-component-name="MessageAnswer"
        px="md"
        py="sm"
      >
        {content}
      </Box>
    </Box>
  );
}
