interface SayOptions {
  lang?: string;
  voice?: string;
}

let selectedVoice: SpeechSynthesisVoice | null = null;

async function getVoices() {
  const GET_VOICES_TIMEOUT = 2000; // two second timeout

  const voices = window.speechSynthesis.getVoices();

  if (voices.length) {
    return voices;
  }

  const voiceschanged = new Promise(resolve => {
    speechSynthesis.addEventListener('voiceschanged', resolve, { once: true });
  });

  const timeout = new Promise(resolve => {
    setTimeout(resolve, GET_VOICES_TIMEOUT);
  });

  // whatever happens first, a voiceschanged event or a timeout.
  await Promise.race([voiceschanged, timeout]);

  return window.speechSynthesis.getVoices();
}

export function parseMessage(message: string) {
  return message.replace(/`{3}\w+/g, '').replace(/`/g, '');
}

export async function say(input: string, options?: SayOptions) {
  const { lang = 'en-US', voice } = options || {};

  // reset any previous utterances
  speechSynthesis.cancel();

  if (!selectedVoice) {
    const voices = await getVoices();

    selectedVoice =
      voices.find(v =>
        voice ? v.name === voice : v.lang === lang && v.name.startsWith('Google'),
      ) || null;
  }

  const utterance = new SpeechSynthesisUtterance(input);

  utterance.lang = lang;

  if (selectedVoice) {
    utterance.voice = selectedVoice;
  }

  return new Promise((resolve, reject) => {
    utterance.onend = resolve;
    utterance.onerror = reject;

    speechSynthesis.speak(utterance);

    const r = setInterval(() => {
      if (!speechSynthesis.speaking) {
        clearInterval(r);
      } else {
        speechSynthesis.pause();
        speechSynthesis.resume();
      }
    }, 14000);
  });
}
