import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, BoxProps, px } from '@gilbarbara/components';

interface Props extends BoxProps {
  active?: boolean;
  muted?: boolean;
  size?: number;
  speaking?: boolean;
}

const blinkRed = keyframes`
  0% {
    box-shadow: 0 0 5px #f11a08;
    width: 45%;
    height: 45%;
  }

  50% {
    box-shadow: 0 0 25px #ff6b5f;
    width: 50%;
    height: 50%;
    margin: 25%;
  }

  100% {
    box-shadow: 0 0 5px #f11a08;
    width: 45%;
    height: 45%;
  }
`;

const blinkRedShadow = keyframes`
  0% {
    box-shadow: inset 0 3px 0 #2e4452, inset 0 5px 0 #392c33;
  }

  50% {
    box-shadow: inset 0 3px 0 #52352e, inset 0 5px 0 #312a25;
  }

  100% {
    box-shadow: inset 0 3px 0 #2e4452, inset 0 5px 0 #392c33;
  }
`;

const blinkYellow = keyframes`
  0% {
    box-shadow: 0 0 5px #ffeb1c;
  }

  50% {
    box-shadow: 0 0 25px #fff;
  }

  100% {
    box-shadow: 0 0 5px #ffeb1c;
  }
`;

const scale = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled(Box)((props: Pick<Props, 'active' | 'size'>) => {
  const { active, size } = props;

  return css`
    animation: ${active ? scale : 'none'} 2s ease-in-out infinite;
    background: #2d2a2b linear-gradient(#494852, #2a2a30);
    box-shadow: inset 0 1px 0 rgba(255, 255, 225, 0.25), 0 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 200px;
    border: 1px solid #231f20;
    width: ${px(size)};
    height: ${px(size)};
  `;
});

const Metal = styled.div`
  background: #417d9f linear-gradient(#43637c, #2c3c4b);
  border-radius: 50%;
  border-top: 2px solid #b8ccd7;
  box-shadow: 0 2px 0 #21262c, 0 4px 0 #232429, 0 6px 0 #040003, 0 0 20px #000, 0 5px 20px #000,
    0 -2px 0 #6d93a6;
  box-sizing: border-box;
  height: 90%;
  margin: 5%;
  padding: 5px;
  width: 90%;
`;

const Light = styled.div((props: Pick<Props, 'active' | 'speaking'>) => {
  const { active, speaking } = props;

  return css`
    animation: ${(active || speaking) &&
    css`
      ${blinkRedShadow} 2s infinite ease-in-out;
    `};
    background: #ffe913;
    background: radial-gradient(ellipse at center, #920000 39%, #2d0408 67%, #000000 71%);
    border-radius: 50%;
    box-shadow: inset 0 3px 0 #2e4452, inset 0 5px 0 #392c33;
    height: 100%;
    position: relative;
    width: 100%;
  `;
});

const GlowingRed = styled.div((props: Pick<Props, 'speaking'>) => {
  const { speaking } = props;

  return css`
    animation: ${speaking &&
    css`
      ${blinkRed} 2s infinite ease-in-out;
    `};
    background: #f11a08;
    border-radius: 50%;
    box-shadow: 0 0 15px #f11a08;
    height: 45%;
    margin: 28%;
    position: absolute;
    width: 45%;
  `;
});

const GlowingYellow = styled.div((props: Pick<Props, 'speaking' | 'muted'>) => {
  const { muted, speaking } = props;

  return css`
    animation: ${speaking &&
    css`
      ${blinkYellow} 2s infinite ease-in-out;
    `};
    background: #ffe913;
    border-radius: 50%;
    box-shadow: 0 0 5px #ffe913;
    height: 40%;
    opacity: ${muted ? 0.3 : 1};
    margin: 30%;
    width: 40%;
  `;
});

export default function HAL(props: Props): JSX.Element {
  const { active, muted, size, speaking, ...rest } = props;

  return (
    <Wrapper active={active} size={size} {...rest}>
      <Metal>
        <Light speaking={speaking}>
          <GlowingRed speaking={speaking}>
            <GlowingYellow muted={muted} speaking={speaking} />
          </GlowingRed>
        </Light>
      </Metal>
    </Wrapper>
  );
}
