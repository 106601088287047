import { FormEvent, useState } from 'react';
import {
  Box,
  Button,
  ButtonUnstyled,
  FormGroup,
  Icon,
  Input,
  Menu,
  MenuDivider,
  MenuItem,
  Modal,
} from '@gilbarbara/components';
import annyang from 'annyang';

import { useAppContext } from '~/modules/context';
import { theme } from '~/modules/theme';

interface Form extends HTMLFormElement {
  elements: HTMLFormControlsCollection & {
    contextSize: HTMLInputElement;
    historyMaxSize: HTMLInputElement;
    maxTokens: HTMLInputElement;
  };
}

export default function Header(): JSX.Element {
  const { setAppState, state } = useAppContext();
  const [isActive, setActive] = useState(false);

  const handleClickLogout = () => {
    setAppState({ apiKey: '' });
    annyang.abort();
  };

  const handleToggleModal = () => {
    setActive(s => !s);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { contextSize, historyMaxSize, maxTokens } = (event.target as Form).elements;

    setAppState({
      contextSize: Number(contextSize.value),
      historyMaxSize: Number(historyMaxSize.value),
      maxTokens: Number(maxTokens.value),
    });

    setActive(false);
  };

  return (
    <Box as="header" position="absolute" right={theme.spacing.md} top={theme.spacing.md}>
      <Menu>
        <MenuItem>
          <ButtonUnstyled onClick={handleToggleModal} padding="sm">
            <Icon mr="xs" name="cog" size={24} /> Settings
          </ButtonUnstyled>
        </MenuItem>
        <MenuDivider />
        <MenuItem>
          <ButtonUnstyled onClick={handleClickLogout} padding="sm">
            <Icon mr="xs" name="log-off" size={24} /> Logout
          </ButtonUnstyled>
        </MenuItem>
      </Menu>
      <Modal isActive={isActive} onClose={handleToggleModal} title="Settings">
        <Box maxWidth={400} width="100vw">
          <form onSubmit={handleSubmit}>
            <FormGroup
              assistiveText="The number of previous messages used for context"
              label="Message context"
            >
              <Input defaultValue={state.contextSize} name="contextSize" required type="number" />
            </FormGroup>
            <FormGroup assistiveText="The number of past messages to keep" label="History size">
              <Input
                defaultValue={state.historyMaxSize}
                name="historyMaxSize"
                required
                type="number"
              />
            </FormGroup>
            <FormGroup
              assistiveText="The maximum number of tokens used in the api call"
              label="Max tokens"
            >
              <Input defaultValue={state.maxTokens} name="maxTokens" required type="number" />
            </FormGroup>

            <Box flexBox justify="space-between">
              <Button invert onClick={handleToggleModal} type="button">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}
