import { mergeTheme } from '@gilbarbara/components';

export const primaryColor = '#f11a08';
export const secondaryColor = '#ffeb1c';

export const theme = mergeTheme({
  colors: {
    primary: primaryColor,
    secondary: secondaryColor,
  },
});
