import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useIsFirstRun } from '@gilbarbara/hooks';
import { produce } from 'immer';

import { appState, name } from '~/config';

import { AppState } from '~/types';

import useLocalStorageState, { SetState } from './useLocalStorageState';

interface Props {
  children: ReactNode;
}

export const AppContext = createContext<{
  setAppState: SetState<AppState>;
  state: AppState;
}>({
  state: appState,
  setAppState: () => undefined,
});
AppContext.displayName = 'AppContext';

export function AppProvider({ children }: Props) {
  const [state, setState] = useLocalStorageState(name, appState);
  const isFirstRun = useIsFirstRun();
  let nextState: AppState;

  if (isFirstRun) {
    nextState = produce(state, draft => {
      if ('completions' in draft) {
        delete draft.completions;
      }

      Object.entries(appState).forEach(([key, value]) => {
        const item = draft[key as keyof AppState];

        if (typeof item === 'undefined') {
          Object.assign(draft, { [key]: value });
        }
      });

      draft.currentId = null;
      draft.isSpeaking = false;
      draft.isListening = false;
      draft.isProcessing = false;
    });

    setState(nextState);
  } else {
    nextState = state;
  }

  const value = useMemo(
    () => ({
      state: nextState,
      setAppState: setState,
    }),
    [nextState, setState],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext(): {
  setAppState: SetState<AppState>;
  state: AppState;
} {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}
