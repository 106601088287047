import { request } from '@gilbarbara/helpers';
import { ChatCompletionRequestMessage, CreateChatCompletionResponse } from 'openai';

interface GetCompletionOptions {
  apiKey: string;
  context: ChatCompletionRequestMessage[];
  maxTokens?: number;
  temperature?: number;
}

export async function getCompletion(prompt: string, options: GetCompletionOptions) {
  const { apiKey, context, maxTokens, temperature = 0.4 } = options;

  const response = await request<CreateChatCompletionResponse>(
    'https://api.openai.com/v1/chat/completions',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: {
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'You are a friendly and sarcastic companion' },
          ...context,
          { role: 'user', content: prompt },
        ],
        temperature,
        max_tokens: maxTokens,
      },
    },
  );

  const { message } = response.choices[0];

  if (!message) {
    throw new Error('No response from OpenAI');
  }

  return message.content;
}
