import { useCallback } from 'react';
import annyang from 'annyang';

import { useAppContext } from '~/modules/context';
import { say } from '~/modules/helpers';

import { AppState, History } from '~/types';

export function useHistory() {
  const { setAppState } = useAppContext();

  const addToHistory = useCallback(
    (item: History) => {
      setAppState(({ history, historyMaxSize }) => ({
        history: [item, ...history.slice(-historyMaxSize)],
      }));
    },
    [setAppState],
  );

  const deleteHistoryItem = useCallback(
    (id: string) => {
      setAppState(({ history }) => ({
        history: history.filter(c => c.id !== id),
      }));
    },
    [setAppState],
  );

  const resetHistory = useCallback(() => {
    setAppState({ history: [] });
  }, [setAppState]);

  const updateHistoryItem = useCallback(
    (id: string, data: Partial<Omit<History, 'id'>>) => {
      setAppState(({ history }) => ({
        history: history.map(item => {
          if (item.id === id) {
            return { ...item, ...data };
          }

          return item;
        }),
      }));
    },
    [setAppState],
  );

  return { addToHistory, deleteHistoryItem, resetHistory, updateHistoryItem };
}

export function useSpeak() {
  const { setAppState } = useAppContext();

  return useCallback(
    (text: string, stateBefore?: Partial<AppState>, stateAfter?: Partial<AppState>) => {
      annyang.abort();
      setAppState({ isSpeaking: true, ...stateBefore });

      return say(text)
        .then(() => {
          setAppState({ isSpeaking: false, ...stateAfter });
          annyang.start();
        })
        .catch(({ error }: SpeechSynthesisErrorEvent) => {
          if (error !== 'interrupted') {
            setAppState({ isSpeaking: false, ...stateAfter });
            annyang.start();
          }
        });
    },
    [setAppState],
  );
}
