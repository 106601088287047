import { FormEvent, KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormGroup, H1, H4, Input, Page } from '@gilbarbara/components';

import { useAppContext } from '~/modules/context';

import { description, name } from '~/config';

import HAL from '~/components/HAL';

interface Form extends HTMLFormElement {
  elements: HTMLFormControlsCollection & {
    key: HTMLInputElement;
  };
}

export default function Connect() {
  const [keyValue, setKeyValue] = useState('');
  const { setAppState } = useAppContext();
  const navigate = useNavigate();

  const handleChange = (event: KeyboardEvent<HTMLInputElement>) => {
    setKeyValue(event.currentTarget.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { key } = (event.target as Form).elements;

    setAppState({ apiKey: key.value });
    navigate('/');
  };

  return (
    <Page centered name="Connect">
      <Box maxWidth={640} width="100%">
        <HAL mb="xl" mx="auto" size={200} />
        <H1>{name}</H1>
        <H4 mb="xl">{description}</H4>
        <form method="POST" onSubmit={handleSubmit}>
          <FormGroup
            assistiveText="You can find it in your OpenAI account. It won't be stored anywhere."
            label="OpenAI API Key"
            name="key"
          >
            <Input
              name="key"
              onChange={handleChange}
              placeholder="Enter your API Key here"
              required
              type="text"
            />
          </FormGroup>

          <Button disabled={keyValue.length < 50} type="submit">
            Send
          </Button>
        </form>
      </Box>
    </Page>
  );
}
