import { MouseEvent } from 'react';
import { Box, ButtonUnstyled } from '@gilbarbara/components';

import { useAppContext } from '~/modules/context';
import { parseMessage } from '~/modules/helpers';

import { useHistory, useSpeak } from './hooks';
import Message from './Message';

export default function Messages() {
  const {
    setAppState,
    state: { currentId, history, isSpeaking },
  } = useAppContext();
  const speak = useSpeak();
  const { deleteHistoryItem, resetHistory } = useHistory();

  const handleClickRemove = (event: MouseEvent<HTMLButtonElement>) => {
    const { id = '' } = event.currentTarget.dataset;

    deleteHistoryItem(id);
  };

  const handleClickReset = () => {
    resetHistory();
  };

  const handleClickSpeak = (event: MouseEvent<HTMLButtonElement>) => {
    const { id } = event.currentTarget.dataset;

    if (isSpeaking && currentId === id) {
      speechSynthesis.cancel();
      setAppState({ currentId: null, isSpeaking: false });
    } else {
      const item = history.find(d => d.id === id);

      if (item?.answer) {
        speak(parseMessage(item.answer), { currentId: id }, { currentId: null });
      }
    }
  };

  return (
    <Box data-component-name="Messages" maxWidth={640} textAlign="left" width="100%">
      {history.map(item => (
        <Message
          key={item.id}
          {...item}
          currentId={currentId}
          isSpeaking={isSpeaking}
          onClickRemove={handleClickRemove}
          onClickSpeak={handleClickSpeak}
        />
      ))}
      {!!history.length && (
        <Box data-component-name="MessagesClear" textAlign="center">
          <ButtonUnstyled onClick={handleClickReset} variant="gray">
            Clear history
          </ButtonUnstyled>
        </Box>
      )}
    </Box>
  );
}
