import { AppState } from '~/types';

export const appState: AppState = {
  apiKey: '',
  contextSize: 3,
  currentId: null,
  history: [],
  historyMaxSize: 50,
  isListening: false,
  isProcessing: false,
  isSpeaking: false,
  maxTokens: 2048,
};

export const name = 'VoxAI';
export const description =
  'A speech-to-text and text-to-speech tool that enable natural and efficient communication with AI';
