import { useCallback, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import type { Dispatch } from 'react';

// in memory fallback used then `localStorage` throws an error
export const inMemoryData = new Map<string, unknown>();

type SetStateAction<S> = Partial<S> | ((previousState: S) => Partial<S>);
export type SetState<S> = Dispatch<SetStateAction<S>>;

export type LocalStorageOptions = {
  serializer?: {
    parse: (value: string) => unknown;
    stringify: (value: unknown) => string;
  };
  storageSync?: boolean;
};

export type LocalStorageState<T> = [
  T,
  SetState<T>,
  {
    isPersistent: boolean;
    removeItem: () => void;
  },
];

export default function useLocalStorageState<T>(
  key: string,
  initialValue: T | (() => T),
  options?: LocalStorageOptions,
): LocalStorageState<T> {
  const [defaultValue] = useState(initialValue);

  // SSR support
  // - on the server, return a constant value
  // - this makes the implementation simpler and smaller because the `localStorage` object is
  //   `undefined` on the server
  if (typeof window === 'undefined') {
    return [
      defaultValue,
      (): void => undefined,
      {
        isPersistent: true,
        removeItem: (): void => undefined,
      },
    ];
  }

  const serializer = options?.serializer;

  // disabling ESLint because the above if statement can be executed only on the server. the value
  // of `window` can't change between calls.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useBrowserLocalStorageState(
    key,
    defaultValue,
    options?.storageSync,
    serializer?.parse,
    serializer?.stringify,
  );
}

function useBrowserLocalStorageState<T>(
  key: string,
  defaultValue: T,
  storageSync: boolean = true,
  parse: (value: string) => unknown = parseJSON,
  stringify: (value: unknown) => string = JSON.stringify,
): LocalStorageState<T> {
  if (!inMemoryData.has(key) && localStorage.getItem(key) === null) {
    // reasons for `localStorage` to throw an error:
    // - maximum quota is exceeded
    // - under Mobile Safari (since iOS 5) when the user enters private mode
    //   `localStorage.setItem()` will throw
    // - trying to access localStorage object when cookies are disabled in Safari throws
    //   "SecurityError: The operation is insecure."
    try {
      localStorage.setItem(key, stringify(defaultValue));
    } catch {
      // noop
    }
  }

  // we keep the `parsed` value in a ref because `useSyncExternalStore` requires a cached version
  const storageValue = useRef<{ item: string | null; parsed: T }>({
    item: null,
    parsed: defaultValue,
  });
  const value = useSyncExternalStore(
    useCallback(
      onStoreChange => {
        const onChange = (localKey: string): void => {
          if (key === localKey) {
            onStoreChange();
          }
        };

        callbacks.add(onChange);

        return (): void => {
          callbacks.delete(onChange);
        };
      },
      [key],
    ),

    () => {
      const item = localStorage.getItem(key);

      if (inMemoryData.has(key)) {
        storageValue.current = {
          item,
          parsed: inMemoryData.get(key) as T,
        };
      } else if (item !== storageValue.current.item) {
        let parsed: T | undefined;

        try {
          parsed = item === null ? defaultValue : (parse(item) as T);
        } catch {
          parsed = defaultValue;
        }

        storageValue.current = {
          item,
          parsed,
        };
      }

      return storageValue.current.parsed;
    },

    // istanbul ignore next
    () => defaultValue,
  );
  const setState = useCallback(
    (newValue: SetStateAction<T>): void => {
      const nextValue =
        newValue instanceof Function ? newValue(storageValue.current.parsed) : newValue;
      const nextState = { ...storageValue.current.parsed, ...nextValue };

      try {
        localStorage.setItem(key, stringify(nextState));

        inMemoryData.delete(key);
      } catch {
        inMemoryData.set(key, nextState);
      }

      triggerCallbacks(key);
    },
    [key, stringify],
  );

  // - syncs change across tabs, windows, iframes
  // - the `storage` event is called only in all tabs, windows, iframe's except the one that
  //   triggered the change
  useEffect(() => {
    if (!storageSync) {
      return undefined;
    }

    const onStorage = (event: StorageEvent): void => {
      if (event.storageArea === localStorage && event.key === key) {
        triggerCallbacks(key);
      }
    };

    window.addEventListener('storage', onStorage);

    return (): void => window.removeEventListener('storage', onStorage);
  }, [key, storageSync]);

  return useMemo(
    () => [
      value,
      setState,
      {
        isPersistent: value === defaultValue || !inMemoryData.has(key),
        removeItem(): void {
          inMemoryData.delete(key);
          localStorage.removeItem(key);

          triggerCallbacks(key);
        },
      },
    ],
    [key, setState, value, defaultValue],
  );
}

// notifies all instances using the same `key` to update
const callbacks = new Set<(key: string) => void>();

function triggerCallbacks(key: string): void {
  for (const callback of callbacks) {
    callback(key);
  }
}

// a wrapper for `JSON.parse()` that supports "undefined" value. otherwise,
// `JSON.parse(JSON.stringify(undefined))` returns the string "undefined" not the value `undefined`
function parseJSON(value: string): unknown {
  return value === 'undefined' ? undefined : JSON.parse(value);
}
